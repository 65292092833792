import { Avatar, AvatarProps } from 'antd';
import { UserWithExtraOptionals } from '../../types';

type UserAvatarProps = {
  user?: Pick<UserWithExtraOptionals, 'firstName' | 'lastName' | 'avatar'>;
  size?: AvatarProps['size'];
};

export const UserAvatar = ({ user, size = 'small' }: UserAvatarProps) => {
  if (!user) {
    return null;
  }

  if (user.avatar) {
    return <Avatar size={size} src={user.avatar.url} />;
  }

  return (
    <Avatar size={size}>
      {user.firstName[0]}
      {user.lastName[0]}
    </Avatar>
  );
};
