import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, theme } from 'antd';
import ReactQuill from 'react-quill';
import { SendOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';
import { t } from '@gowgates/utils';
import { quillWithSendRules } from '@gowgates/core';

const modules = {
  toolbar: {
    container: '#toolbar'
  }
};

const StyledQuillWithSend = styled.div`
  ${(props) => quillWithSendRules(props.$token, props.$hasError)}
`;

const QuillWithSend = ({ value = '', onChange = () => {}, onCancel, loading = false, id }) => {
  const form = Form.useFormInstance();
  const { token } = theme.useToken();

  const onKeyDown = (event) => {
    if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
      form.submit();
    }
  };

  return (
    <StyledQuillWithSend
      className="quill-with-send"
      $token={token}
      $hasError={form.getFieldError(id).length > 0}
    >
      <ReactQuill
        value={value}
        modules={modules}
        onKeyDown={onKeyDown}
        onChange={onChange}
        formats={['bold', 'italic', 'underline', 'list']}
      />

      <div id="toolbar">
        <div className="d-flex justify-content-between">
          <div>
            <span className="ql-formats">
              <button type="button" role="menu" className="ql-bold" aria-label="Bold" />
              <button type="button" role="menu" className="ql-italic" aria-label="Italic" />
              <button type="button" role="menu" className="ql-underline" aria-label="Underline" />
            </span>

            <span className="ql-formats">
              <button
                type="button"
                role="menu"
                className="ql-list"
                value="ordered"
                aria-label="Ordered"
              />
              <button
                type="button"
                role="menu"
                className="ql-list"
                value="bullet"
                aria-label="Bullet"
              />
            </span>
          </div>

          <span className="ql-formats submit-btn">
            {onCancel && <Button onClick={onCancel}>{t('globals.cancel')}</Button>}
            <Button type="primary" icon={<SendOutlined />} htmlType="submit" loading={loading} />
          </span>
        </div>
      </div>
    </StyledQuillWithSend>
  );
};

QuillWithSend.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired
};

export default QuillWithSend;
